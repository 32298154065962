import React from 'react';

import styled from 'styled-components';

const HeadlineStyled = styled.h1`
  margin-top: 0;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Catamaran;
  color: var(--color-font-main);
`;

function Headline(props) {
  return <HeadlineStyled {...props}>{props.children}</HeadlineStyled>;
}

export default Headline;
