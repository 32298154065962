import React from 'react';

import styled from 'styled-components';

const FormStyled = styled.div`
  background: var(--color-grey-light);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 75vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  padding-top: 100px;
`;

function Form(props) {
  return <FormStyled {...props}>{props.children}</FormStyled>;
}

export default Form;
