import React from 'react';
import Edittourtemplate from '../templates/Edittourtemplate';
import useFetch from '../../hooks/useFetch';


function EditTourPage() {
    const accessToken = localStorage.getItem('accessToken');
    const productGroup = useFetch(`${process.env.REACT_APP_API}/master/product-groups`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
  });
  return (
    productGroup && (
      <>
        <Edittourtemplate productGroup={productGroup} />
      </>
    )
  );
}

export default EditTourPage;
