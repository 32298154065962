import React from 'react';
import useFetch from '../../hooks/useFetch';
import LineFeedingTemplate from '../templates/Linefeedingtemplate';

function LineFeedingPage(props) {
  const tour = props.match.params.tour || null;
  const accessToken = localStorage.getItem('accessToken');
  const movements = useFetch(
    `${process.env.REACT_APP_API}/movement/tour/${tour}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    }
  );

  return (
    movements && (
      <>
        <LineFeedingTemplate tour={tour} movements={movements} />
      </>
    )
  );
}

export default LineFeedingPage;
