import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import {Background, ButtonWrapper, Headline, Paragraph, PopupStyled, TextButton} from "./styles";



const Button = styled.div`
  position: relative;
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  margin-top: 10px;
  border: none;
  height: 40px;
  line-height: 42px;
  padding: 0 16px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  &:active,
  &:focus {
    background: var(--color-main);
  }

`;

function EmergencyPopUp({
  emergency, requestHelp, tour, solveIssue, setshowEmergencyPopup, ...props
}) {
  return <Background>
    <PopupStyled {...props}>
    <div>
      <Headline>Sende Notfall-Anfrage</Headline>
      <Paragraph>Du kannst über den Notfall-Button eine Hilfe-Anfrage an alle anderen aktiven
        Benutzer stellen. Möchtest du Hilfe anfordern?</Paragraph>
    </div>
    <ButtonWrapper>
      <TextButton onClick={() => {
        setshowEmergencyPopup(false);
      }}>Abbrechen</TextButton>

      <Button onClick={() => {
        requestHelp(tour)
      }}>Hilfe anfordern</Button>
    </ButtonWrapper>
  </PopupStyled>
  </Background>
}

export default EmergencyPopUp;
