import React from 'react';

import styled from 'styled-components';

import userIcon from '../../assets/user-icon.svg';

const UserIconStyled = styled.span`
  position: absolute;
  background: url(${userIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 21px;
  height: 21px;
  left: 20px;
  top: 22px;
`;

function UserIcon(props) {
  return <UserIconStyled {...props} />;
}

export default UserIcon;
