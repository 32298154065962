import React from 'react';
import { Redirect } from 'react-router-dom';
function LogoutPage() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('username');
  localStorage.removeItem('linefeeder');
  localStorage.removeItem('beladen');
  localStorage.removeItem('customGroups');
  localStorage.removeItem('userType');


  return (
    <>
      <Redirect to="/?logout=true" />
    </>
  );
}

export default LogoutPage;
