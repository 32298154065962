import React from 'react';
import styled from 'styled-components';

const Headline = styled.p`
  color: var(--color-grey-light-font);
  text-transform: uppercase;
  font-size: 15px;
  line-height: 15px;
  margin: 0;
`;

const Content = styled.p`
  color: var(--color-font-main);
  font-size: 20px;
  line-height: 25px;
  margin: 0;
`;

const InfoItem = ({ headline, content }) => {
  return (
    <div>
      <Headline>{headline}</Headline>
      <Content>{content}</Content>
    </div>
  );
};

export default InfoItem;
