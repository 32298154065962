import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';

import arrowIcon from '../../assets/arrow.svg';

// atoms
import Button from '../atoms/button';
import Label from '../atoms/label';
import Input from '../atoms/input';
import Headline from '../atoms/headline';
import UserIcon from '../atoms/usericon';
import PasswordIcon from '../atoms/passwordicon';
import Form from '../atoms/form';
import Errormessage from '../atoms/errormessage';

// organisms
import Header from '../organisms/header';
import useQuery from '../../hooks/useQuery';

function LoginTemplate() {
  const [state, setState] = useState({
    username: null,
    password: null,
    message: '',
    accessToken: localStorage.getItem(`accessToken`)
  });

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  function handleKeyup(event) {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  async function handleSubmit(e) {
    const response = await fetch(`${process.env.REACT_APP_API}/user/signin`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        name: state.username,
        password: state.password
      })
    });

    const responseObj = await response.json();
    if (responseObj.accessToken) {
      localStorage.setItem('accessToken', responseObj.accessToken);
      localStorage.setItem('linefeeder', responseObj.linefeeder);
      localStorage.setItem('beladen', responseObj.beladen);
      localStorage.setItem('userType', responseObj.userType);
      localStorage.setItem('username', state.username);

      setState({
        ...state,
        accessToken: responseObj.accessToken
      });
    } else {
      setState({
        ...state,
        message: responseObj.message
      });
    }
  }

  const { message, accessToken } = state;

  const query = useQuery();
  const loggedOutMessage =
    query.get('logout') === 'true' ? 'Erfolgreich ausgeloggt.' : null;

  return (
    <>
      <div className="container">
        <Header onLandingpage />

        <div className="row">
          <div className="col s12">
            <Headline>Login</Headline>
          </div>
        </div>
        {accessToken && (
          <>
            <Redirect to="/choosedialog" />
          </>
        )}
      </div>
      <Form>
        <div className="container">
          <Label>
            <UserIcon />
            <Input
              type="text"
              onChange={handleChange}
              onKeyUp={handleKeyup}
              name="username"
              placeholder="Benutzername"
            />
          </Label>
          <Label>
            <PasswordIcon />
            <Input
              type="password"
              onChange={handleChange}
              onKeyUp={handleKeyup}
              name="password"
              placeholder="Passwort"
            />
          </Label>
          {state.message ? <Errormessage>{state.message}</Errormessage> : ''}
          {!message && loggedOutMessage ? loggedOutMessage : null}
          <Button icon={arrowIcon} className="right" onClick={handleSubmit}>
            Anmelden
          </Button>
        </div>
      </Form>
    </>
  );
}

export default LoginTemplate;
