export const fetcher = async (url, method = 'GET', body = null) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await fetch(`${process.env.REACT_APP_API}/${url}`, {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body
  });
  return await response.json();
};
