import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Headline from '../atoms/headline';
import Header from '../organisms/header';
import Input from '../atoms/input';
import BoxIcon from '../atoms/boxicon';
import Label from '../atoms/label';
import Popup from '../molecules/popup';
import Emergencypopup from '../molecules/emergencypopup';
import Helpneededpopup from '../molecules/helpneededpopup';
import useEmergency from '../../hooks/useEmergency';
import { fetcher } from '../../utils/fetch';
import RequestedEmergency from '../molecules/requestedEmergency';
import NoHelpFound from '../molecules/noHelpFound';
import HelpFound from '../molecules/helpFound';
import UnloadAtBand from '../organisms/UnloadAtBand/UnloadAtBand';

const ProductGroupListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .disabled {
    color: #f4f4f4 !important;
  }
`;
const Color = styled.span`
  color: #c95959;
  ${(props) =>
    props.ampel &&
    props.ampel === 'green' &&
    `
color: #73AC5E
`}

  ${(props) =>
    props.ampel &&
    props.ampel === 'orange' &&
    `
color: #EE7203
`}
${(props) =>
    props.ampel &&
    props.ampel === 'blue' &&
    `
color: #797eff
`}
`;
const ProductGroup = styled.a`
  color: white;
  width: 100px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 12px;
  margin-bottom: 10px;
  background: #c95959;
  ${(props) =>
    props.ampel &&
    props.ampel === 'green' &&
    `
        background: #73AC5E
    `}

  ${(props) =>
    props.ampel &&
    props.ampel === 'orange' &&
    `
        background: #EE7203
    `}
    ${(props) =>
    props.ampel &&
    props.ampel === 'grey' &&
    `
          background: grey
      `}
      ${(props) =>
    props.ampel &&
    props.ampel === 'blue' &&
    `
        background: #797eff
      `}
`;
const Timeleft = styled.span`
  font-size: 20px;
  margin-right: 20px;
  display: inline-block;
`;
const ProductGroupDetails = styled.div`
  font-weight: 500;
  // > span {
  //     color: #8F8F98;
  // }
`;

const ProductGroupLabel = styled(Label)`
  align-self: baseline;
  flex-grow: 0;
`;

const BeltInput = styled(Input)`
  background: var(--color-input-background-onwhite) !important;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  color: var(--color-main);
  margin-bottom: 20px;
`;

const ProductGroupInput = styled(Input)`
  background: var(--color-input-background-onwhite) !important;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  max-width: 120px;
  color: var(--color-main);
`;

const SOSButton = styled.div`
  color: white;
  width: 100px;
  height: 50px;
  border-radius: 10px;
  float: right;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 12px;
  margin-bottom: 10px;
  background: #c95959;
  cursor: pointer;
  ${(props) =>
    props?.disabled &&
    `
    cursor: initial;
    background: #f4f4f4;
  `}
`;

function LineFeedingTemplate({ movements, tour }) {
  const [state, setState] = useState({
    message: '',
    movements: movements.latestMovements,
    belt: null,
    block: false,
    status: movements.status
  });

  const [unloadAtBand, setUnloadAtBand] = useState({
    movements: [],
    belt: null,
    message: ''
  });

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const {
    checkForEmergency,
    checkForEmergencies,
    currentEmergencyRequest,
    showEmergencyPopup,
    setshowEmergencyPopup,
    requestHelp,
    declineRequest,
    confirmRequest,
    solveIssue,
    setTour
  } = useEmergency();

  const [isEmergency, setisEmergency] = useState(false);
  const [emergencyRes, setemergencyRes] = useState({});

  const [hasBeenshown, sethasBeenshown] = useState(false);
  const [helpFounded, sethelpFounded] = useState({});

  //! enable
  const [currentSOS, setcurrentSOS] = useState(0); //* <------------

  const refHiddenBeltInput = useRef();
  const refBeltInput = useRef();
  const emergencyIntervalRef = useRef();
  const history = useHistory();

  const extractItemsAtBelt = (movements) => {
    const obj = {};
    for (let index = 0; index < movements.length; index++) {
      const movement = movements[index];
      if (movement.activeMovement) {
        obj[movement.activeMovement.id] =
          state.status && state.status.status === 'STOPPED'
            ? movement.activeMovement.restamount
            : movement.itemsAtBelt;
      }
    }

    return obj;
  };

  async function RefetchTour(currentTour) {
    const accessToken = localStorage.getItem('accessToken');
    const movementsFetched = await fetch(
      `${process.env.REACT_APP_API}/movement/tour/${currentTour}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    const movementObj = await movementsFetched.json();

    const newMovements = movementObj.latestMovements.map((movement) => {
      if (movement.deadline) {
        const countdown = calculateTime(movement.deadline, new Date());
        return { ...movement, countdown };
      } else {
        return movement;
      }
    });

    await setState({
      ...state,
      movements: newMovements,
      status: movementObj.status
    });
    return newMovements;
  }

  const [itemsAtBelt, setItemsAtBelt] = useState();
  useEffect(() => {
    setTour(tour);
    RefetchTour(tour);
    checkForEmergencies(tour);
    setItemsAtBelt(extractItemsAtBelt(state.movements));
  }, []);

  useEffect(() => {
    const { current } = refHiddenBeltInput;
    current.focus();
  }, [refHiddenBeltInput]);

  const interval = useRef(0);

  const block = useRef(false);

  const onChangeItemsAtBelt = async (id, value) => {
    const newItemsAtBelt = { ...state.itemsAtBelt };
    itemsAtBelt[id] = value;
    await setState({ ...state, itemsAtBelt: newItemsAtBelt });

    setTimer();
  };

  const updateItemsAtBelt = (index, id, value) => {
    let valString = Number(value).toString().replace(/-/g, '');

    if (valString.length > 3) {
      return;
    }

    if (value === '') {
      const newItemsAtBelt = { ...state.itemsAtBelt };
      newItemsAtBelt[id] =
        state.status && state.status.status === 'STOPPED'
          ? state.movements[index].restamount
          : state.movements[index].itemsAtBelt;
      setItemsAtBelt(newItemsAtBelt);
    } else {
      patchItemsAtBelt(value, id);
    }
  };

  const calculateTime = (date1, date2) => {
    const deadline = new Date(date1);
    const currentTime = new Date(date2);
    var diff =
      deadline.getTime() - currentTime.getTime() > 0
        ? deadline.getTime() - currentTime.getTime()
        : currentTime.getTime() - deadline.getTime();

    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    return {
      hh,
      mm,
      ss,
      missed: deadline.getTime() - currentTime.getTime() > 0
    };
  };

  useEffect(() => {
    clearTimeout(interval.current);
    setTimer();
    // setTimerFetch();
    return () => {
      clearTimeout(interval.current);
    };
  }, [state.movements]);

  const setTimer = () => {
    if (interval.current) {
      clearTimeout(interval.current);
    }
    interval.current = setTimeout(async () => {
      const executeTimeout = async () => {
        const newDateSeconds = new Date().getTime();
        clearTimeout(interval.current);
        if (Math.floor(newDateSeconds / 1000) % 5 === 0 && !block.current) {
          try {
            const newMovements = await RefetchTour(tour);
            await setItemsAtBelt(extractItemsAtBelt(newMovements));
          } catch (e) {
            setTimeout(executeTimeout.bind(null, this), 1000);
          }
        } else {
          const newMovements = state.movements.map((movement) => {
            if (movement.deadline) {
              const countdown = calculateTime(movement.deadline, new Date());
              return {
                ...movement,
                countdown: countdown
              };
            } else {
              return movement;
            }
          });

          await setState({
            ...state,
            movements: newMovements
          });
        }
      };

      executeTimeout();
    }, 1000);
  };

  const patchItemsAtBelt = async (value, movementId) => {
    const data = { amount: value };

    clearTimeout(interval.current);
    const accessToken = localStorage.getItem('accessToken');
    try {
      await fetch(
        `${process.env.REACT_APP_API}/movement/changerest/${movementId}`,
        {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(data)
        }
      );

      const movementsFetched = await fetch(
        `${process.env.REACT_APP_API}/movement/tour/${tour}`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer'
        }
      );
      const movementObj = await movementsFetched.json();
      const newMovements = movementObj.latestMovements.map((movement) => {
        if (movement.deadline) {
          const countdown = calculateTime(movement.deadline, new Date());
          return { ...movement, countdown };
        } else {
          return movement;
        }
      });

      await setState({ ...state, movements: newMovements });
      await setItemsAtBelt(extractItemsAtBelt(newMovements));
    } catch (err) {
      console.error(err);
    }
  };

  const dropoff = async (belt, deliveryNumberOrTakt) => {
    if (!belt) {
      return;
    }
    let beltResponseObj = null;
    if (!!deliveryNumberOrTakt) {
      beltResponseObj = await fetcher(
        `movement/dropoff/${belt}`,
        'PATCH',
        JSON.stringify({
          delivery: deliveryNumberOrTakt
        })
      );
    } else {
      beltResponseObj = await fetcher(`movement/dropoff/${belt}`, 'PATCH');
    }
    if (beltResponseObj?.movements) {
      setUnloadAtBand({ movements: beltResponseObj.movements, belt });
    } else if (beltResponseObj === true) {
      setUnloadAtBand({
        movements: [],
        belt: null,
        message: ''
      });
      await RefetchTour(tour);
      await setTimer();
      setState({
        ...state,
        belt: null
      });
    } else if (beltResponseObj === false) {
      setUnloadAtBand({
        ...unloadAtBand,
        message: `Sendung oder Takt nicht gefunden`
      });
    }
  };

  useEffect(() => {
    if (emergencyIntervalRef.current) {
      clearInterval(emergencyIntervalRef.current);
    }
    emergencyIntervalRef.current = setInterval(() => {
      checkForEmergencies(tour);
    }, 5000);
    return () => clearInterval(emergencyIntervalRef.current);
  }, []);

  var CHECK_BELT = useRef(null);
  function handleChange(e) {
    if (CHECK_BELT.current) {
      clearTimeout(CHECK_BELT.current);
    }
    CHECK_BELT.current = setTimeout(dropoff.bind(false, e.target.value), 500);

    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const onFocusHandler = (e) => {
    e.currentTarget.blur();
    setIsReadOnly(false);
    refBeltInput.current.focus();
  };

  const handleScan = async (deliveryNumberOrTakt, belt) => {
    dropoff(belt, deliveryNumberOrTakt);
  };

  const handleUnloadAtBandClose = async () => {
    setUnloadAtBand({
      movements: [],
      belt: null,
      message: ''
    });
    await RefetchTour(tour);
    await setTimer();
  };

  return (
    <>
      <div className="container">
        <Header />
        {showPopup && (
          <Popup
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Kein weiterer Container <br />
            gefunden.
          </Popup>
        )}
        {unloadAtBand.movements?.length > 0 && (
          <UnloadAtBand
            movements={unloadAtBand.movements}
            handleScan={handleScan}
            belt={unloadAtBand.belt}
            closeUnload={handleUnloadAtBandClose}
            message={unloadAtBand.message}
          />
        )}

        <div className="row">
          <div className="col s12">
            <Headline>Übersicht {tour}</Headline>
          </div>
        </div>

        <BeltInput
          name="belt"
          onChange={handleChange}
          placeholder="BELT"
          value={state.belt || ''}
          readOnly={isReadOnly}
          inputRef={(ref) => (refBeltInput.current = ref)}
        />
        <input
          style={{ opacity: 0 }}
          placeholder="BELT"
          onFocus={onFocusHandler}
          name="belt"
          value={state.belt || ''}
          ref={refHiddenBeltInput}
        />
        {currentEmergencyRequest &&
        currentEmergencyRequest.myRequest &&
        currentEmergencyRequest.willHelp !== false ? (
          <RequestedEmergency
            {...currentEmergencyRequest}
            solveIssue={solveIssue}
          />
        ) : (
          <></>
        )}
        {currentEmergencyRequest &&
        !currentEmergencyRequest.myRequest &&
        currentEmergencyRequest.willHelp === null ? (
          <Helpneededpopup
            {...currentEmergencyRequest}
            declineRequest={declineRequest}
            confirmRequest={confirmRequest}
          />
        ) : (
          <></>
        )}
        {currentEmergencyRequest &&
        currentEmergencyRequest.myRequest &&
        currentEmergencyRequest.willHelp === false ? (
          <NoHelpFound {...currentEmergencyRequest} solveIssue={solveIssue} />
        ) : (
          <></>
        )}
        {currentEmergencyRequest && currentEmergencyRequest.iAmHelping ? (
          <HelpFound {...currentEmergencyRequest} solveIssue={solveIssue} />
        ) : (
          <></>
        )}
        <div>
          {showEmergencyPopup ? (
            <Emergencypopup
              emergency={currentEmergencyRequest}
              setshowEmergencyPopup={setshowEmergencyPopup}
              requestHelp={requestHelp}
              tour={tour}
              checkForEmergency={checkForEmergency}
            />
          ) : null}
        </div>
        <div></div>
        <div>
          {isEmergency &&
          !hasBeenshown &&
          localStorage.getItem('username') !== emergencyRes.user ? (
            <Helpneededpopup
              emergencyRes={emergencyRes}
              setisEmergency={setisEmergency}
              sethasBeenshown={sethasBeenshown}
              helpFounded={helpFounded}
              setshowEmergencyPopup={setshowEmergencyPopup}
              setemergencyRes={setemergencyRes}
              sethelpFounded={sethelpFounded}
              setcurrentSOS={setcurrentSOS}
              currentSOS={currentSOS}
            ></Helpneededpopup>
          ) : null}
        </div>
        <br />
        <div className="row">
          <div className="col s12">
            {state.message ? state.message : ''}
            {state.movements &&
              state.movements.map((movement, index) => {
                const pad = (num, size) => {
                  return ('000000000' + num).substr(-size);
                };

                let stoplight = movement.color;

                return movement.countdown ? (
                  <ProductGroupListItem key={`product-group-listitem-${index}`}>
                    {movement.activeMovement &&
                    movement.passiveMovement &&
                    movement.activeMovement.master.source === 'LFS' ? (
                      <ProductGroup
                        onClick={() => {
                          history.push(
                            `/tourexec/${tour}/${movement.passiveMovement.id}/${movement.activeMovement.id}`
                          );
                        }}
                        ampel={stoplight}
                      >
                        {movement.activeMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.activeMovement &&
                    !movement.passiveMovement &&
                    movement.activeMovement.master.source === 'LFS' ? (
                      <ProductGroup
                        ampel={stoplight}
                        onClick={() => {
                          setShowPopup(true);
                        }}
                      >
                        {movement.activeMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.activeMovement &&
                    movement.activeMovement.master.source === 'MANUAL' ? (
                      <ProductGroup
                        onClick={() => {
                          history.push(
                            `/tourexecmanual/${tour}/${movement.activeMovement.id}`
                          );
                        }}
                        ampel={stoplight}
                      >
                        {movement.activeMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}

                    <ProductGroupDetails>
                      <Color
                        className={!!interval.current ? '' : 'disabled'}
                        ampel={stoplight}
                      >
                        {state.status.status === 'CONTINUED' ? (
                          <Timeleft>
                            {movement.countdown.missed ? '' : '-'}
                            {pad(movement.countdown.hh, 2)}:
                            {pad(movement.countdown.mm, 2)}:
                            {pad(movement.countdown.ss, 2)}
                          </Timeleft>
                        ) : (
                          <>
                            {state.status.status === 'STOPPED' &&
                            state.status.type === 'MANUAL'
                              ? 'Band Angehalten'
                              : ''}
                            {state.status.status === 'STOPPED' &&
                            state.status.type === 'AUTOMATIC' &&
                            state.status.inShift &&
                            state.status.inBreak
                              ? 'In Pause'
                              : ''}
                            {state.status.status === 'STOPPED' &&
                            state.status.type === 'AUTOMATIC' &&
                            !state.status.inShift &&
                            !state.status.inBreak
                              ? 'Keine Schicht'
                              : ''}
                          </>
                        )}
                      </Color>
                    </ProductGroupDetails>
                    <ProductGroupLabel>
                      <form onSubmit={(e) => e.preventDefault()}>
                        {movement.activeMovement && (
                          <ProductGroupInput
                            type="number"
                            onChange={(e) => {
                              let value = e.target.value;
                              let valString = Number(e.target.value).toString();
                              const hasMinus = valString.includes('-');

                              if (hasMinus) {
                                valString = valString.replace(/-/g, '');
                              }

                              if (valString.length > 3) {
                                value = hasMinus
                                  ? `-${valString.slice(0, 3)}`
                                  : valString.slice(0, 3);
                              }

                              clearTimeout(interval.current);
                              onChangeItemsAtBelt(
                                movement.activeMovement.id,
                                value
                              );
                            }}
                            onFocus={(e) => {
                              block.current = true;
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                block.current = false;
                                updateItemsAtBelt(
                                  index,
                                  movement.activeMovement.id,
                                  e.target.value,
                                  movement.activeMovement.delivery
                                );
                              }
                            }}
                            onBlur={(e) => {
                              block.current = false;
                            }}
                            value={itemsAtBelt[movement.activeMovement.id]}
                          />
                        )}

                        <BoxIcon />
                      </form>
                    </ProductGroupLabel>
                  </ProductGroupListItem>
                ) : (
                  <ProductGroupListItem>
                    {movement.currentGroup &&
                    movement.currentGroup.source === 'MANUAL' &&
                    !movement.transportedMovement ? (
                      <ProductGroup
                        onClick={() => {
                          history.push(
                            `/tourexecmanualfirst/${tour}/${movement.currentGroup.id}`
                          );
                        }}
                        ampel={stoplight}
                      >
                        {movement.currentGroup.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.currentGroup &&
                    movement.currentGroup.source === 'MANUAL' &&
                    movement.transportedMovement ? (
                      <ProductGroup
                        onClick={() => {
                          history.push(
                            `/tourexecmanualsecond/${tour}/${movement.passiveMovement.id}`
                          );
                        }}
                        ampel={stoplight}
                      >
                        {movement.currentGroup.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.passiveMovement &&
                    movement.currentGroup &&
                    movement.currentGroup.source === 'LFS' ? (
                      <ProductGroup
                        ampel={stoplight}
                        onClick={() => {
                          setShowPopup(true);
                        }}
                      >
                        {movement.passiveMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {!movement.passiveMovement &&
                    movement.currentGroup &&
                    movement.currentGroup.source === 'LFS' ? (
                      <ProductGroup
                        onClick={() => {
                          history.push(
                            `/tourexecprd/${tour}/${movement.currentGroup.id}`
                          );
                        }}
                        ampel={stoplight}
                      >
                        {movement.currentGroup.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                  </ProductGroupListItem>
                );
              })}
          </div>
        </div>
        <SOSButton
          disabled={currentEmergencyRequest || false}
          onClick={() => {
            !currentEmergencyRequest && setshowEmergencyPopup(true);
          }}
        >
          Notfall
        </SOSButton>
      </div>
    </>
  );
}

export default LineFeedingTemplate;
