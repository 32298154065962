import React, { useContext, createContext, useState } from 'react';

const MenuContext = createContext();
const MenuToggleContext = createContext();

export function useMenu() {
  return useContext(MenuContext);
}

export function useMenuToggle() {
  return useContext(MenuToggleContext);
}

export default function MenuProvider({ children }) {
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <MenuContext.Provider value={showMenu}>
      <MenuToggleContext.Provider value={toggleMenu}>
        {children}
      </MenuToggleContext.Provider>
    </MenuContext.Provider>
  );
}
