import React from "react";
import { Route, Redirect } from "react-router-dom";

const Privateroute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        const authenticated = localStorage.getItem('accessToken');
        return (
            authenticated
        ? <Component {...props} />
        : <Redirect to='/login' />
    )}} />
  )
export default Privateroute;