import React from 'react';

import styled from 'styled-components';
import {Background, ButtonWrapper, Headline, Paragraph, PopupStyled, TextButton} from "./styles";

const Button = styled.div`
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  margin-top: 10px;
  border: none;
  height: 40px;
  padding: 0 20px;
  line-height: 44px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 48%;
  
  &:active,
  &:focus {
    background: var(--color-main);
  }
`;

function HelpNeededPopUp({
    id, user, helpingUser, tour, confirmRequest, declineRequest, ...props
                         }) {

    return <Background>
        <PopupStyled {...props}>
        <>
            <Headline>Notfall-Anfrage</Headline>
            <Paragraph>Benutzer {user.name} der Tour {tour} benötigt Unterstützung. Bist du verfügbar und kannst
                dich auf den Weg zu ihm machen, um zu helfen?</Paragraph>
        </>
        <ButtonWrapper>
            <TextButton onClick={() => {
                declineRequest(id);
            }}>Abbrechen</TextButton>
        <Button onClick={() => {
            confirmRequest(id);
        }}>Bestätigen</Button>
        </ButtonWrapper>
    </PopupStyled></Background>;
}

export default HelpNeededPopUp;
