import React from 'react';

import styled from 'styled-components';

const ChoiceStyled = styled.a`
  color: var(--color-choice-font);
  background: var(--color-choice-background);
  height: 80px;
  width: 100%;
  border-radius: 20px;
  font-size: 20px;
  font-family: Catamaran;
  font-weight: bold;
  line-height: 80px;
  margin-bottom: 30px;
  padding: 0 30px;
  display: block;

  &:active {
    background: var(--color-main);
    color: #fff;
  }
`;

function Choice(props) {
  return <ChoiceStyled {...props}>{props.children}</ChoiceStyled>;
}

export default Choice;
