import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

const ChoiceStyled = styled.a`
  color: var(--color-main);
  background: white;
  height: 80px;
  width: 100%;
  border-radius: 20px;
  font-size: 20px;
  font-family: Catamaran;
  font-weight: bold;
  line-height: 80px;
  margin-bottom: 30px;
  padding: 0 30px;
  display: block;

  &:active {
    background: var(--color-main);
    color: #fff;
  }
`;

function CustomTourChoice() {
  const history = useHistory();
  return (
    <ChoiceStyled
      onClick={() => {
        history.push(`/custom-tour/overview`);
      }}
      >Custom tour</ChoiceStyled>
  );
}

export default CustomTourChoice;
