import React, { useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Loginpage from './components/pages/Loginpage';
import Logoutpage from './components/pages/Logoutpage';

import Beltspeedpage from './components/pages/Beltspeedpage';
import Privateroute from './components/helpers/Privateroute';
import Choosedialogpage from './components/pages/Choosedialogpage';
import Unloadpage from './components/pages/Unloadpage';
import Tourpage from './components/pages/Tourpage';
import Linefeedingpage from './components/pages/Linefeedingpage';
import Tourexecutepage from './components/pages/Tourexecutepage';
import TourexecutePrdpage from './components/pages/TourexecutePrdpage';
import Tourexecutemanualpage from './components/pages/Tourexecutemanualpage';
import Tourexecutemanualfirstpage from './components/pages/Tourexecutemanualfirstpage';
import Tourexecutemanualsecondpage from './components/pages/Tourexecutemanualsecondpage';
import Customtourpage from './components/pages/Customtourpage';
import Edittourpage from './components/pages/Edittourpage';

import './assets/materialize.css';
import MenuPage from './components/pages/MenuPage';
import MenuProvider from './components/helpers/MenuContext';
import { StateProvider } from './components/helpers/StoreContext';


function App() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem('DarkMode') === 'true' || false
  );

  const toggleDarkMode = () => {
    localStorage.setItem('DarkMode', !darkMode);
    setDarkMode(!darkMode);
  };

  return (
    <>
      <div className="ThemeWrapper" id={darkMode ? 'DarkTheme' : 'LightTheme'}>
        <MenuProvider>
          <StateProvider>
            <BrowserRouter>
              <Route path="/" exact component={Loginpage} />
              <Route path="/logout" exact component={Logoutpage} />
              <Privateroute
                path="/choosedialog"
                exact
                component={Choosedialogpage}
              />
              <Privateroute path="/tourexecprd/:tour/:masterId" exact component={TourexecutePrdpage} />
              <Privateroute path="/tourexec/:tour/:passive/:active" exact component={Tourexecutepage} />
              <Privateroute path="/tourexecmanual/:tour/:active" exact component={Tourexecutemanualpage} />
              <Privateroute path="/tourexecmanualsecond/:tour/:passive" exact component={Tourexecutemanualsecondpage} />
              <Privateroute path="/tourexecmanualfirst/:tour/:masterId" exact component={Tourexecutemanualfirstpage} />
              
              <Privateroute path="/unload" exact component={Unloadpage} />
              <Privateroute path="/tour" exact component={Tourpage} />
              <Privateroute
                path="/linefeeding/:tour"
                exact
                component={Linefeedingpage}
              />
              <Privateroute path="/belt" exact component={Beltspeedpage} />
              {localStorage.getItem("userType") === "ADMIN" ? <Privateroute path="/custom-tour/overview" exact component={Customtourpage} /> : null}
              {localStorage.getItem("userType") === "ADMIN" ?  <Privateroute path="/custom-tour/edit-tour" exact component={Edittourpage} /> : null}
              <MenuPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </BrowserRouter>
          </StateProvider>
        </MenuProvider>
      </div>
    </>
  );
}

export default App;
