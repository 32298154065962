import React from 'react';

import styled from 'styled-components';

const logoColors = {
  dev: '#c1c0bb',
  stage: '#8abd5d',
  prod: '#df942c'
};

const LogoStyled = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: ${logoColors[
    process.env.REACT_APP_FAV_ICO.toString().split('.')[0]
  ]};
  margin-right: 15px;
`;

function Logo(props) {
  return <LogoStyled {...props} />;
}

export default Logo;
