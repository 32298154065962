import React from 'react';

import styled from 'styled-components';


const PopupStyled = styled.div`
  position: fixed;
  top: 5vh;
  left: 5vw;
  width: 90vw;
  height: 90vh;
  background: white;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 1px 12px 0px #80808036;
`;

const Textposition = styled.div`
  font-size: 20px;
  text-align: center;
  top: 280px;
  display: block;
  position: relative;
`;

const Button = styled.div`
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  position: absolute;
  border: none;
  height: 50px;
  padding: 17px 20px;
  line-height: 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 130px;
  top: 380px;
  left: 50%;
  transform: translateX(-50%);
  &:active,
  &:focus {
    background: var(--color-main);
  }

`;

function Popup(props) {
  return <PopupStyled {...props}>

      <Textposition>{props.children}</Textposition>
    <Button>OK</Button>
    </PopupStyled>;
}

export default Popup;
