import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: var(--color-main);
  color: var(--color-main-font);
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 30px;
  padding: 20px 25px;
  line-height: 35px;
  font-family: var(--font-accent);
`;

const DescriptionWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

export default DescriptionWrapper;
