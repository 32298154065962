import React from 'react';
import styled from 'styled-components';

const Area = styled.div`
  background: var(--color-grey-light);
  height: 200px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 20px 0;
`;

const InputArea = ({ children }) => (
  <Area>
    <div className="container">{children}</div>
  </Area>
);

export default InputArea;
