import React, { useState, useEffect, useRef, Fragment } from 'react';

// atoms
import Headline from '../atoms/headline';
import Input from '../atoms/input';
import Form from '../atoms/form';
import Label from '../atoms/label';
// organisms
import Header from '../organisms/header';
import Errormessage from '../atoms/errormessage';

import UnloadingProcessTable from './UnloadingProcessTable';

function Unloadtemplate() {
  const STATUS = {
    NONE: 'NONE',
    DELIVERY_SCANNED: 'DELIVERY_SCANNED',
    GATE_SCANNED: 'GATE_SCANNED'
  };

  const [state, setState] = useState({
    delivery: '',
    unloadingGate: '',
    status: STATUS.NONE,
    message: ''
  });

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isReadOnlyGate, setIsReadOnlyGate] = useState(true);
  const [lastUnloadedItems, setLastUnloadedItems] = useState([]);

  const refHiddenDeliveryInput = useRef();
  const refDeliveryInput = useRef();

  const refHiddenGateInput = useRef();
  const refGateInput = useRef();

  useEffect(() => {
    const { current } = refHiddenDeliveryInput;
    if (!!current) {
      setIsReadOnlyGate(true);
      current.focus();
    }
  }, [state.status]);

  useEffect(() => {
    const { current } = refHiddenDeliveryInput;
    if (!!current) {
      current.focus();
    }
  }, [refHiddenDeliveryInput]);

  useEffect(() => {
    const { current } = refHiddenGateInput;

    if (!!current) {
      setIsReadOnly(true);
      current.focus();
    }
  }, [state]);

  useEffect(async () => {
    const accessToken = localStorage.getItem('accessToken');
    const unloadedResponse = await fetch(
      `${process.env.REACT_APP_API}/movement/unload/last-items`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );

    const lastUnloaded = await unloadedResponse.json();
    setLastUnloadedItems(lastUnloaded);
  }, []);

  async function getGate(gate) {
    const accessToken = localStorage.getItem('accessToken');
    const movementResponse = await fetch(
      `${process.env.REACT_APP_API}/movement/unload/${state.delivery}/${gate}`,
      {
        method: 'PATCH',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    const movement = await movementResponse.json();

    if (movement && movement.master.unloadingGate === gate) {
      setLastUnloadedItems(prevState => ([movement, ...prevState]));
      setState({
        ...state,
        gate: '',
        status: STATUS.NONE,
        message: '',
        delivery: ''
      });
    } else {
      setState({
        ...state,
        gate: '',
        message: 'TOR NICHT GEFUNDEN'
      });
    }
  }

  async function getDelivery(deliverynumber) {
    const accessToken = localStorage.getItem('accessToken');
    const movementResponse = await fetch(
      `${process.env.REACT_APP_API}/movement/unload/${deliverynumber}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    const movement = await movementResponse.json();

    if (movement) {
      setState({
        ...state,
        delivery: movement.delivery,
        status: STATUS.DELIVERY_SCANNED,
        message: ''
      });
    } else {
      setState({
        ...state,
        delivery: '',
        message: 'SENDUNG NICHT GEFUNDEN'
      });
    }
  }

  var CHECK_DELIVERY = null;
  function handleChange(e) {
    if (CHECK_DELIVERY) {
      clearTimeout(CHECK_DELIVERY);
    }
    if (
      state.status === STATUS.NONE &&
      e.target.name === 'delivery' &&
      e.target.value
    ) {
      CHECK_DELIVERY = setTimeout(getDelivery.bind(false, e.target.value), 200);
    }
    if (
      state.status === STATUS.DELIVERY_SCANNED &&
      e.target.name === 'unloadingGate' &&
      e.target.value
    ) {
      CHECK_DELIVERY = setTimeout(getGate.bind(false, e.target.value), 200);
    }
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const onFocusDeliveryHandler = (e) => {
    e.currentTarget.blur();
    setIsReadOnly(false);
    refDeliveryInput.current.focus();
  };

  const onFocusGateHandler = (e) => {
    e.currentTarget.blur();
    setIsReadOnlyGate(false);
    refGateInput.current.focus();
  };

  return (
    <>
      <div className="container" style={{ zIndex: '5' }}>
        <Header />

        <div className="row">
          <div className="col s12">
            <Headline>
              {state.status === STATUS.NONE ? 'Behälter aufnehmen' : ''}
              {state.status === STATUS.DELIVERY_SCANNED
                ? 'Beladetor scannen'
                : ''}
            </Headline>
          </div>
        </div>
      </div>
      <Form style={{ zIndex: '-1' }}>
        <div className="container">
          <div className="row">
            <div className="col s12">
              {state.message ? <Errormessage>{state.message}</Errormessage> : ''}
              <Label>
                {state.status === STATUS.NONE ? (
                  <>
                    <Input
                      type="text"
                      value={state.delivery}
                      onChange={handleChange}
                      name="delivery"
                      placeholder="SENDUNG SCANNEN"
                      readOnly={isReadOnly}
                      inputRef={(ref) => (refDeliveryInput.current = ref)}
                    />
                    {/* Disabled Keybord on input autofocus */}
                    <input
                      style={{ opacity: 0 }}
                      placeholder="SENDUNG SCANNEN"
                      onFocus={onFocusDeliveryHandler}
                      name="delivery"
                      value={state.delivery}
                      ref={refHiddenDeliveryInput}
                    />
                  </>
                ) : (
                  ''
                )}
                {state.status === STATUS.DELIVERY_SCANNED ? (
                  <>
                    <Input
                      type="text"
                      value={state.gate}
                      onChange={handleChange}
                      name="unloadingGate"
                      placeholder="TOR SCANNEN"
                      readOnly={isReadOnlyGate}
                      inputRef={(ref) => (refGateInput.current = ref)}
                    />
                    {/* Disabled Keybord on input autofocus */}
                    <input
                      style={{ opacity: 0 }}
                      placeholder="TOR SCANNEN"
                      onFocus={onFocusGateHandler}
                      name="gate"
                      value={state.gate}
                      ref={refHiddenGateInput}
                    />
                  </>
                ) : (
                  ''
                )}
              </Label>
            </div>
          </div>

          {lastUnloadedItems.length > 0 && <UnloadingProcessTable unloadedItems={lastUnloadedItems} />}
        </div>
      </Form>
    </>
  );
}

export default Unloadtemplate;
