import React from 'react';

import styled from 'styled-components';

const ButtonStyled = styled.button`
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  border: none;
  height: 66px;
  padding: 0 20px;
  line-height: 66px;
  min-width: 180px;

  &:active,
  &:focus {
    background: var(--color-main);
  }
  &:after {
    content: ' ';
    background: url(${(props) => props.icon && props.icon});
    background-size: 18px;
    background-position: center;
    width: 22px;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    top: 0;
  }

  ${(props) =>
    props.size === 'small' &&
    `
        height: 50px;
        line-height: 50px;
        padding: 0 17px;
        width: 135px;
        
        &:after {
            content: ' ';
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            position: absolute;
            right: 17px;
            top: 17px;
            
            
        }
    `}

  ${(props) =>
    props.size === 'medium' &&
    `
        height: 50px;
        padding: 10px 17px;
        width: 135px;
        
        &:after {
            content: ' ';
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            position: absolute;
            right: 17px;
            top: 17px;
        }
    `}

    ${(props) => props.marginTop && ` margin-top: ${props.marginTop}; `}
    border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;

  position: absolute;
  right: 10px;
  bottom: 10px;
  ${(props) =>
    props.disabled &&
    `
        background: #EDEDF4;
        color: white;
    `}
`;

function Button(props) {
  return <ButtonStyled {...props}>{props.children}</ButtonStyled>;
}

export default Button;
