import React from 'react';
import TourExecuteTemplate from '../templates/Tourexecutetemplate';
import useFetch from '../../hooks/useFetch';

function TourexecutePrdpage(props) {
    const masterId = props.match.params.masterId;
    const tour = props.match.params.tour;
    const accessToken = localStorage.getItem('accessToken');
    
    const delivery = useFetch(`${process.env.REACT_APP_API}/movement/forscanprd/${masterId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    
    return tour && delivery &&  (
        <>
            <TourExecuteTemplate delivery={delivery} tour={tour}/>
        </>
    )
}


export default TourexecutePrdpage;