import React from 'react';
import CustomTourTemplate from '../templates/Customtourtemplate';
import useFetch from '../../hooks/useFetch';


function CustomTourPage() {
    const accessToken = localStorage.getItem('accessToken');
    const customGroups = localStorage.getItem('customGroups');
    const movements = useFetch(`${process.env.REACT_APP_API}/movement/tour/custom-tour/?tours=${customGroups}`,
     {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
  });

  return (
    movements && (
      <>
        <CustomTourTemplate movements={movements} />
      </>
    )
  );
}

export default CustomTourPage;