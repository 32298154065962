import React, { useState, useEffect, useRef, Fragment } from 'react';
// atoms
import Headline from '../../atoms/headline';
import Input from '../../atoms/input';
import Form from '../../atoms/form';
import Label from '../../atoms/label';
import { Linklist, Link, Listitem } from './components/link';
// organisms
import Errormessage from '../../atoms/errormessage';

import UnloadingProcessTable from './UnloadingProcessTable';

function UnloadAtBand({ movements, belt, handleScan, closeUnload, message }) {
  const [state, setState] = useState({
    delivery: '',
    unloadingGate: '',
    message: message
  });

  const [cMovements, setCMovements] = useState(movements);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const refHiddenDeliveryInput = useRef();
  const refDeliveryInput = useRef();

  useEffect(() => {
    setState({ ...state, message: message, delivery: '' });
  }, [message]);

  useEffect(() => {
    setCMovements(movements);
    setState({ ...state, delivery: '' });
    return () => {
      setCMovements([]);
    };
  }, [movements]);

  useEffect(() => {
    const { current } = refHiddenDeliveryInput;
    if (!!current) {
      current.focus();
    }
  }, [refHiddenDeliveryInput]);

  var CHECK_DELIVERY = null;
  function handleChange(e) {
    if (CHECK_DELIVERY) {
      clearTimeout(CHECK_DELIVERY);
    }
    CHECK_DELIVERY = setTimeout(
      handleScan.bind(false, e.target.value, belt),
      200
    );
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const onFocusDeliveryHandler = (e) => {
    e.currentTarget.blur();
    setIsReadOnly(false);
    refDeliveryInput.current.focus();
  };

  return (
    <div className="custom-modal" style={{ zIndex: '5' }}>
      <div className="container">
        <div className="row">
          <div className="col s12">
            <Headline>Behälter abladen</Headline>
          </div>
        </div>
      </div>
      <Form style={{ zIndex: '-1' }}>
        <div className="container">
          <div className="row">
            <div className="col s12">
              <Linklist>
                <Listitem className="back-icon">
                  <Link
                    onClick={() => {
                      closeUnload();
                    }}
                  >
                    Zurück
                  </Link>
                </Listitem>
              </Linklist>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              {state.message ? (
                <Errormessage>{state.message}</Errormessage>
              ) : (
                ''
              )}
              <Label>
                <>
                  <Input
                    type="text"
                    value={state.delivery}
                    onChange={handleChange}
                    name="delivery"
                    placeholder="SENDUNG SCANNEN"
                    readOnly={isReadOnly}
                    inputRef={(ref) => (refDeliveryInput.current = ref)}
                  />
                  {/* Disabled Keybord on input autofocus */}
                  <input
                    style={{ opacity: 0 }}
                    placeholder="SENDUNG SCANNEN"
                    onFocus={onFocusDeliveryHandler}
                    name="delivery"
                    value={state.delivery}
                    ref={refHiddenDeliveryInput}
                  />
                </>
              </Label>
            </div>
          </div>

          {cMovements.length > 0 && (
            <UnloadingProcessTable unloadedItems={cMovements} />
          )}
        </div>
      </Form>
    </div>
  );
}

export default UnloadAtBand;
