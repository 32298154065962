import React from 'react';
import styled from 'styled-components';

// icons

import ScanIcon from '../../assets/scan.svg';

const InputStyled = styled.input`
  width: 100% !important;
  border: none !important;
  background-color: var(--color-input-background) !important;
  border-radius: 10px !important;
  padding-left: 50px;
  height: 66px;
  line-height: 66px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--color-input-font);
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-image: ${({ icon }) => {
    switch (icon) {
      case 'scan':
        return `url(${ScanIcon});`;
      default:
        return 'none;';
    }
  }}
  

  &::placeholder {
    color: var(--color-input-placeholder);
  }

  &:focus {
    &::placeholder {
      color: var(--color-main);
    }
    outline: none !important;
    -webkit-appearance: none !important;
  }

  ${(props) =>
    props.icon === false
      ? `
        padding-left: 20px;
    `
      : ''}
`;

function Input(props) {
  const {inputRef} = props;
  return <InputStyled ref={inputRef} {...props}>{props.children}</InputStyled>;
}

export default Input;
