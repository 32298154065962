import React, { useEffect, useState } from 'react';
import {Background, Button, Headline, Paragraph, PopupStyled} from "./styles";

function RequestedEmergency({
    id, user, tour, helpingUser, helpingUserTour, willHelp, solved, confirmed, index, closeCurrentEmergencyRequest, ...props
}) {
    const solveIssue = async () => {
        const accessToken = localStorage.getItem('accessToken');
        await fetch(
            `${process.env.REACT_APP_API}/emergencyhelper/issue-solved`,
            {
                method: 'PATCH',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    solvedSOSid: id,
                })
            }
        );
    }

    return <Background>
        <PopupStyled {...props}>
            {!helpingUser && !solved ?
                <>
                    <Headline>Warten auf Rückmeldung</Headline>
                    <Paragraph>Deine Notfall-Anfrage wurde an alle anderen Benutzer verschickt. Sobald Hilfe
                        unterwegs oder falls keine Hilfe verfügbar ist, wirst du über eine Nachricht
                        informiert.</Paragraph>
                </>
                : null}

            {helpingUser && !solved ?
                <>
                    <Headline>Hilfe ist unterwegs!</Headline>
                    <Paragraph>Benutzer <b>{helpingUser}</b> von Tour <b>{helpingUserTour}</b> ist unterwegs zu dir, um dich bei deinem Problem zu unterstützen.</Paragraph>
                </>
                : null
            }
            <Button onClick={() => {solveIssue();}}>Abbrechen</Button>
        </PopupStyled>
    </Background>;
}

export default RequestedEmergency;
