import React from 'react';

import styled from 'styled-components';

const NameStyled = styled.div`
    display: inline-block;
    line-height: 50px;
`;

function Name(props) {

    return (
        <NameStyled {...props}>
            {props.children}
        </NameStyled>
    )
}

export default Name;