import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Errormessage from '../atoms/errormessage';
import { useHistory } from 'react-router-dom';
// atoms
import Headline from '../atoms/headline';
import Choice from '../atoms/choice';
import DescriptionWrapper from '../atoms/description';
import Input from '../atoms/input';
import Form from '../atoms/form';
import Label from '../atoms/label';
import InfoItem from '../atoms/infoItem';

// organisms
import Header from '../organisms/header';
import InputArea from '../atoms/inputArea';
import { useEffect } from 'react';

const InfoArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 20px;
`;

const VerticalFiller = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  input {
    margin-bottom: 0;
  }
`;

function TourExecuteManualTemplate({ delivery, tour }) {
  const [state, setState] = useState({
    message: '',
    lastTakt: '',
    delivery: delivery.passiveMovement || delivery.activeMovement || delivery
  });
  const [isReadOnly, setIsReadOnly] = useState(true);

  const refHiddenLastTaktInput = useRef();
  const refLastTaktInput = useRef();

  const history = useHistory();
  const CHECK_DELIVERY = useRef(null);
  var maxTakt =
    parseInt(state.delivery.sequenceEnd) +
    parseInt(state.delivery.master.content);

  if (maxTakt > 999) {
    maxTakt -= 999;
  }
  var maxTaktWithTolerance =
    parseInt(state.delivery.sequenceEnd) +
    parseInt(state.delivery.master.content) +
    parseInt(state.delivery.master.tolerance);

  if (maxTaktWithTolerance > 999) {
    maxTaktWithTolerance -= 999;
  }
  const newTaktStart =
    state.delivery.sequenceEnd !== 999 ? state.delivery.sequenceEnd + 1 : 1;

  async function startTransport(sequenceEnd) {
    const incorrectTaktValidation =
      (!(state.delivery.master && !state.delivery.id) &&
        maxTakt < newTaktStart &&
        parseInt(sequenceEnd) < newTaktStart &&
        parseInt(sequenceEnd) > maxTakt) ||
      (maxTakt > newTaktStart &&
        (parseInt(sequenceEnd) < newTaktStart ||
          parseInt(sequenceEnd) > maxTakt)) ||
      parseInt(sequenceEnd) > 999 ||
      parseInt(sequenceEnd) < 1;

    const incorrectTaktValidationWithTollerance =
      (!(state.delivery.master && !state.delivery.id) &&
        maxTaktWithTolerance < newTaktStart &&
        parseInt(sequenceEnd) < newTaktStart &&
        parseInt(sequenceEnd) > maxTaktWithTolerance) ||
      (maxTaktWithTolerance > newTaktStart &&
        (parseInt(sequenceEnd) < newTaktStart ||
          parseInt(sequenceEnd) > maxTaktWithTolerance)) ||
      parseInt(sequenceEnd) > 999 ||
      parseInt(sequenceEnd) < 1;

    if (incorrectTaktValidation && incorrectTaktValidationWithTollerance) {
      setState({
        ...state,
        lastTakt: '',
        message: 'falscher Takt'
      });
    } else if (
      incorrectTaktValidation &&
      !incorrectTaktValidationWithTollerance &&
      state.delivery.master.checkToleranceDialog &&
      !window.confirm(`Bestätigen Sie den Takt im Toleranzbereich`)
    ) {
      setState({
        ...state,
        lastTakt: '',
        message: 'Falscher Takt oder Toleranz nicht bestätigt'
      });
    } else {
      const accessToken = localStorage.getItem('accessToken');
      if (state.delivery.master && !state.delivery.id) {
          const transportResponse = await fetch(
          `${process.env.REACT_APP_API}/movement/transport_first_takt/${delivery.master.id}/${sequenceEnd}`,
          {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
          }
        );
        const transport = await transportResponse.json();

        if (transport && transport.name !== 'Error') {
          history.push(`/linefeeding/${tour}`);
        } else {
          setState({
            ...state,
            lastTakt: '',
            message: 'falscher Takt'
          });
        }
      } else {
        const transportResponse = await fetch(
          `${process.env.REACT_APP_API}/movement/transport_takt/${delivery.activeMovement.id}/${sequenceEnd}`,
          {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
          }
        );
        const transport = await transportResponse.json();
        if (transport && transport.name !== 'Error') {
          history.push(`/linefeeding/${tour}`);
        } else {
          setState({
            ...state,
            lastTakt: '',
            message: transport.message
          });
        }
      }
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(CHECK_DELIVERY.current);
    };
  }, []);

  useEffect(() => {
    const { current } = refHiddenLastTaktInput;
    current.focus();
  }, [refHiddenLastTaktInput]);

  function handleChange(e) {
    if (CHECK_DELIVERY.current) {
      clearTimeout(CHECK_DELIVERY.current);
    }

    CHECK_DELIVERY.current = setTimeout(
      startTransport.bind(false, e.target.value),
      500
    );
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const onFocusHandler = (e) => {
    e.currentTarget.blur();
    setIsReadOnly(false);
    refLastTaktInput.current.focus();
  };

  return (
    <>
      <VerticalFiller>
        <div className="container">
          <Header />
          <Headline>Fahre Behälter</Headline>
          <div className="row">
            <DescriptionWrapper>
              {state.delivery.master.productdescription}
            </DescriptionWrapper>
            <InfoArea>
              <InfoItem
                headline="Takte / Send."
                content={`${state.delivery.master.content}`}
              />
              {state.delivery.sequenceEnd && (
                <>
                  <InfoItem
                    headline="Min. Takt"
                    content={
                      state.delivery.sequenceEnd !== 999
                        ? state.delivery.sequenceEnd + 1
                        : 1
                    }
                  />
                  <InfoItem headline="Max. Takt" content={maxTakt} />
                </>
              )}

              <InfoItem
                headline="Tor"
                content={state.delivery.master.unloadingGate}
              />
            </InfoArea>
          </div>
        </div>
        <InputArea>
          <Label>
            <div>
              {state.message ? (
                <Errormessage>{state.message}</Errormessage>
              ) : (
                ''
              )}
              <Input
                type="text"
                icon="scan"
                value={state.lastTakt}
                onChange={handleChange}
                name="lastTakt"
                placeholder="LETZTEN TAKT SCANNEN"
                inputRef={(ref) => (refLastTaktInput.current = ref)}
                readOnly={isReadOnly}
              />
              {/* Disabled Keybord on input auto focus */}
              <input
                style={{ opacity: 0 }}
                placeholder="LETZTEN TAKT SCANNEN"
                onFocus={onFocusHandler}
                name="lastTakt"
                value={state.lastTakt}
                ref={refHiddenLastTaktInput}
              />
            </div>
          </Label>
        </InputArea>
      </VerticalFiller>
    </>
  );
}

export default TourExecuteManualTemplate;
