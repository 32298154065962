import { useState } from "react";
import { fetcher } from "../utils/fetch";
export default function useEmergency() {
  const [currentTour, setTour] = useState();
  const [currentEmergencyRequest, setCurrentEmergencyRequest] = useState();
  const [showEmergencyPopup, setshowEmergencyPopup] = useState(false);
  const checkForEmergency = async (id) => {
    const currentEmergencyRequest = await fetcher(`emergencyhelper/${id}`);
    setCurrentEmergencyRequest(currentEmergencyRequest);
  }

  const checkForEmergencies = async (tour) => {
    const res = await fetcher(`emergencyhelper?tour=${tour || currentTour}`);
    if (res) {
      const {id, myRequest, solved, tour, user, willHelp, helpingUser, iAmHelping} = res;
      setCurrentEmergencyRequest({id, myRequest, solved, tour, user, willHelp, iAmHelping,
        helpingUser: helpingUser?.name,
        helpingUserTour: helpingUser?.tour
      });
    } else {
      setCurrentEmergencyRequest(null);
    }
  }

  const confirmRequest = async (id) => {
    await fetcher("emergencyhelper/helper-confirmed", "PATCH", JSON.stringify({
      id,
    }));
    await checkForEmergencies();
  }

  const declineRequest = async (id) => {
    await fetcher("emergencyhelper/helper-declined", "PATCH", JSON.stringify({
      id,
    }));
    await checkForEmergencies();
  }

  const solveIssue = async (id) => {
    await fetcher("emergencyhelper/issue-solved", "PATCH", JSON.stringify({
      solvedSOSid: id,
    }));
    await checkForEmergencies();
  }

  const requestHelp = async (tour) => {
    const currentEmergencyRequest = await fetcher("emergencyhelper", "POST", JSON.stringify({
      tour: tour,
    }));

    setCurrentEmergencyRequest(currentEmergencyRequest);
    setshowEmergencyPopup(false);
    await checkForEmergencies();
  }

  const cancelEmergencyRequest = () => {
    setshowEmergencyPopup(false)
  }

  const closeCurrentEmergencyRequest = () => {
    setCurrentEmergencyRequest(null);
    setshowEmergencyPopup(false);
  }

  return {
    checkForEmergency,
    checkForEmergencies,
    currentEmergencyRequest,
    cancelEmergencyRequest,
    closeCurrentEmergencyRequest,
    showEmergencyPopup,
    setshowEmergencyPopup,
    requestHelp,
    declineRequest,
    confirmRequest,
    solveIssue,
    setTour
  }
}
