import React from 'react';
import { useHistory } from 'react-router-dom';
// atoms
import Choice from '../atoms/choice';
import Headline from '../atoms/headline';

// organisms
import Header from '../organisms/header';


function ChooseDialogTemplate() {
    let history = useHistory();
    
    const goto = (path) => {
        history.push(path)
    }
    return (
        <>
            <div className="container">
                <Header />

                <div className="row">
                    <div className="col s12">
                        <Headline>Dialog wählen</Headline>
                    </div>

                </div>
                <div className="row">
                    <div className="col s12">
                        <Choice onClick={goto("/unload")}>Entladung</Choice>
                        <Choice onClick={goto("/tour")}>Linefeeding</Choice>

                    </div>
                </div>
            </div>
          
        </>
    )
}
 

export default ChooseDialogTemplate;