import React, {useState} from 'react';

import styled from 'styled-components';
import {Background, ButtonWrapper, Headline, Paragraph, PopupStyled, TextButton} from "./styles";

const Button = styled.div`
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  margin-top: 10px;
  border: none;
  height: 40px;
  padding: 0 20px;
  line-height: 44px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  
  &:active,
  &:focus {
    background: var(--color-main);
  }
`;

function HelpFound({ id, user, helpingUser, tour, solveIssue, ...props }) {
  return (
    <Background>
      <PopupStyled {...props}>
        <>
          <Headline>Danke für deine Hilfe!</Headline>
          <Paragraph>
            Vielen Dank für deine aktive Unterstützung. Sobald das Problem
            gelöst wurde, klicke auf den Button.
          </Paragraph>
        </>
        <ButtonWrapper>
          <Button
            onClick={() => {
              solveIssue(id);
            }}
          >
            Bestätigen
          </Button>
        </ButtonWrapper>
      </PopupStyled>
    </Background>
  );
}

export default HelpFound;
