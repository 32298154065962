import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  backdrop-filter: blur(2px);
  filter: blur(0.2);
  top: 0;
  left: 0;
`;
export const PopupStyled = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  top: 18vh;
  left: 5vw;
  right: 5vw;
  width: auto;
  max-width: 500px;
  max-height: 300px;
  height: auto;
  background: white;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 1px 1px 12px 0px #80808036;
  padding: 16px;
`;

export const Headline = styled.h3`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  display: block;
  margin: 0 0 12px 0;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 32px 0;
  color: #444952;

`;

export const Button = styled.div`
  position: relative;
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  border: none;
  height: 40px;
  margin: 0;
  line-height: 44px;
  border-radius: 10px;
  width: 100%;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  &:active,
  &:focus {
    background: var(--color-main);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const TextButton = styled.div`
  font-family: 'Catamaran', sans-serif;
  color: #444952;
  margin-top: 10px;
  border: none;
  height: 40px;
  padding: 0 20px;
  line-height: 44px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 48%;
  

  &:active,
  &:focus {
    background: var(--color-main);
  }
`;
