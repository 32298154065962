import React from 'react';
import styled from 'styled-components';

// atoms
import Nav from '../atoms/nav';
import Logo from '../atoms/logo';
import Name from '../atoms/name';

// image files
import { useMenuToggle } from '../helpers/MenuContext';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function Header({ onLandingpage, inMenu }) {
  const toggleMenu = useMenuToggle();
  return (
    <div className="row">
      <div className="col s12">
        <Nav inMenu={inMenu} onClick={inMenu ? toggleMenu : () => {}}>
          <LogoWrapper onClick={onLandingpage ? () => {} : toggleMenu}>
            <Logo /> <Name>Linefeeder</Name>
          </LogoWrapper>
        </Nav>
      </div>
    </div>
  );
}

export default Header;
