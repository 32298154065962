import React, {createContext, useReducer } from 'react';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;


export const CHANGE_TOUR = "CHANGE_TOUR";


const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer( (state, action) => {
        switch(action.type) {
            case CHANGE_TOUR:
                    return { tour: action.payload };
            default: 
                throw new Error();
        }
    }, initialState );

    return <Provider value={{state, dispatch}}>{children}</Provider>

}

export { store, StateProvider };