import React from 'react';
import Tourexecutemanualtemplate from '../templates/Tourexecutemanualtemplate';
import useFetch from '../../hooks/useFetch';

function TourExecuteManualSecondPage(props) {
    const passive = props.match.params.passive;
    const tour = props.match.params.tour
    const accessToken = localStorage.getItem('accessToken');
    
    const delivery = useFetch(`${process.env.REACT_APP_API}/movement/forscan/${passive}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    
    return tour && delivery &&  (
        <>
            <Tourexecutemanualtemplate delivery={delivery} tour={tour}/>
        </>
    )
}


export default TourExecuteManualSecondPage;