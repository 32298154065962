import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../organisms/header';
import Headline from '../atoms/headline';
import Button from '../atoms/button';
import styled from 'styled-components';
import Label from '../atoms/label';
import Input from '../atoms/input';
import BoxIcon from '../atoms/boxicon';
import { pad } from '../../utils/pad';
import { fetcher } from '../../utils/fetch';


const ProductGroup = styled.a`
  color: white;
  width: 100px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 12px;
  margin-bottom: 10px;
  background: #c95959;

  ${(props) =>
    props.ampel &&
    props.ampel === 'green' &&
    `
        background: #73AC5E
    `}

  ${(props) =>
    props.ampel &&
    props.ampel === 'orange' &&
    `
        background: #EE7203
    `}
    ${(props) =>
    props.ampel &&
    props.ampel === 'grey' &&
    `
          background: grey
      `}
      ${(props) =>
    props.ampel &&
    props.ampel === 'blue' &&
    `
        background: #797eff
      `}
`;

const ProductGroupLabel = styled(Label)`
  align-self: baseline;
  flex-grow: 0;
`;

const ProductGroupListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .disabled {
    color: #f4f4f4 !important;
  }
`;

const ProductGroupInput = styled(Input)`
  background: var(--color-input-background-onwhite) !important;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  max-width: 120px;
  color: var(--color-main);
`;

const ProductGroupDetails = styled.div`
  font-weight: 500;
  // > span {
  //     color: #8F8F98;
  // }
`;
const Color = styled.span`
  color: #c95959;
  ${(props) =>
    props.ampel &&
    props.ampel === 'green' &&
    `
color: #73AC5E
`}

  ${(props) =>
    props.ampel &&
    props.ampel === 'orange' &&
    `
color: #EE7203
`}

${(props) =>
    props.ampel &&
    props.ampel === 'blue' &&
    `
color: #797eff
`}
`;
const Timeleft = styled.span`
  font-size: 20px;
  margin-right: 20px;
  display: inline-block;
`;

const buttonsBox = {
  position: 'sticky',
  background: 'var(--color-main-background)',
  bottom: '0px',
  height: '70px',
};

function CustomTourTemplate({ movements }) {
  const [itemsAtBelt, setItemsAtBelt] = useState();
  const [showPopup, setShowPopup] = useState(false);

  const history = useHistory();

  const [state, setState] = useState({
    message: '',
    movements: movements.latestMovements,
    belt: null,
    block: false,
    status: movements.status
  });
  const interval = useRef(0);
  const block = useRef(false);

  const extractItemsAtBelt = (movements) => {
    const obj = {};
    for (let index = 0; index < movements.length; index++) {
      const movement = movements[index];
      if (movement.activeMovement) {
        obj[movement.activeMovement.id] =
          state.status && state.status.status === 'STOPPED'
            ? movement.activeMovement.restamount
            : movement.itemsAtBelt;
      }
    }
    return obj;
  };

  useEffect(() => {
    clearTimeout(interval.current);
    setTimer();
    return () => {
      clearTimeout(interval.current);
    };
  }, [state.movements]);


  const setTimer = () => {
    if (interval.current) {
      clearTimeout(interval.current);
    }
    interval.current = setTimeout(async () => {
      const executeTimeout = async () => {
        const newDateSeconds = new Date().getTime();
        clearTimeout(interval.current);
        if (Math.floor(newDateSeconds / 1000) % 10 === 0 && !block.current) {
          try {
            const newMovements = await RefetchTour();           
            await setItemsAtBelt(extractItemsAtBelt(newMovements));
          } catch (e) {
            console.log(e);
            setTimeout(executeTimeout.bind(null, this), 1000);
          }
        } else {
          const newMovements = state.movements.map((movement) => {
            if (movement.deadline) {
              const countdown = calculateTime(movement.deadline, new Date());
              return {
                ...movement,
                countdown: countdown
              };
            } else {
              return movement;
            }
          });
          await setState({
            ...state,
            movements: newMovements
          });
        }
      };
      executeTimeout();
    }, 1000);
  };

  async function RefetchTour() {
    const accessToken = localStorage.getItem('accessToken');
    const customGroups = localStorage.getItem('customGroups');
    const movementsFetched = await fetch(
      `${process.env.REACT_APP_API}/movement/tour/custom-tour/?tours=${customGroups}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    const movementObj = await movementsFetched.json();

    const newMovements = movementObj.latestMovements.map((movement) => {
      if (movement.deadline) {
        const countdown = calculateTime(movement.deadline, new Date());
        return { ...movement, countdown };
      } else {
        return movement;
      }
    });

    await setState({ ...state, movements: newMovements, status: movementObj.status });
    return newMovements;
  }

  const calculateTime = (date1, date2) => {
    const deadline = new Date(date1);
    const currentTime = new Date(date2);
    var diff =
      deadline.getTime() - currentTime.getTime() > 0
        ? deadline.getTime() - currentTime.getTime()
        : currentTime.getTime() - deadline.getTime();

    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    return {
      hh,
      mm,
      ss,
      missed: deadline.getTime() - currentTime.getTime() > 0
    };
  };

  useEffect(() => {
    setItemsAtBelt(extractItemsAtBelt(state.movements));
  }, []);

  console.log(state.movements);

  return (
    state.movements && (
      <>
        <div className="container">
          <Header />
          <Headline style={{marginLeft: '16px'}}>Overview custom template</Headline>
          <div className="row">
          <div className="col s12">
            {state.message ? state.message : ''}
            {state.movements &&
              state.movements.map((movement, index) => {
                let movementItemsAtBelt = state.status.status === 'STOPPED' ? movement.activeMovement && movement.activeMovement.restamount : movement.itemsAtBelt;

                let stoplight = movement.color;

                return movement.countdown ? (
                  <ProductGroupListItem key={`product-group-listitem-${index}`}>
                    {movement.activeMovement &&
                      movement.passiveMovement &&
                      movement.activeMovement.master.source === 'LFS' ? (
                      <ProductGroup
                        ampel={stoplight}
                      >
                        {movement.activeMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.activeMovement &&
                      !movement.passiveMovement &&
                      movement.activeMovement.master.source === 'LFS' ? (
                      <ProductGroup
                        ampel={stoplight}
                        onClick={() => {
                          setShowPopup(true)
                        }}
                      >
                        {movement.activeMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.activeMovement &&
                      movement.activeMovement.master.source === 'MANUAL' ? (
                      <ProductGroup
                        ampel={stoplight}
                      >
                        {movement.activeMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    <ProductGroupDetails>
                      <Color
                        className={!!interval.current ? '' : 'disabled'}
                        ampel={stoplight}
                      >
                        {state.status.status === 'CONTINUED' ? (
                          <Timeleft>
                            {movement.countdown.missed ? '' : '-'}
                            {pad(movement.countdown.hh, 2)}:
                            {pad(movement.countdown.mm, 2)}:
                            {pad(movement.countdown.ss, 2)}
                          </Timeleft>
                        ) : (
                          <>
                            {state.status.status === 'STOPPED' &&
                              state.status.type === 'MANUAL'
                              ? 'Band Angehalten'
                              : ''}
                            {state.status.status === 'STOPPED' &&
                              state.status.type === 'AUTOMATIC' &&
                              state.status.inShift && state.status.inBreak
                              ? 'In Pause'
                              : ''}
                            {state.status.status === 'STOPPED' &&
                              state.status.type === 'AUTOMATIC' &&
                              !state.status.inShift && !state.status.inBreak
                              ? 'Keine Schicht'
                              : ''}
                          </>
                        )}
                      </Color>
                    </ProductGroupDetails>
                    <ProductGroupLabel>
                      {movement.activeMovement && (
                        <ProductGroupInput
                          type="number"
                          value={
                            itemsAtBelt[movement.activeMovement.id]
                          }
                          disabled={true}  
                        />
                      )}
                      <BoxIcon />
                    </ProductGroupLabel>

                  </ProductGroupListItem>
                ) : (
                  <ProductGroupListItem>
                    {movement.currentGroup &&
                      movement.currentGroup.source === 'MANUAL' &&
                      !movement.transportedMovement ? (
                      <ProductGroup
                        ampel={stoplight}
                      >
                        {movement.currentGroup.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.currentGroup &&
                      movement.currentGroup.source === 'MANUAL' &&
                      movement.transportedMovement ? (
                      <ProductGroup
                        ampel={stoplight}
                      >
                        {movement.currentGroup.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {movement.passiveMovement && movement.currentGroup &&
                      movement.currentGroup.source === 'LFS' ? (
                      <ProductGroup
                        ampel={stoplight}
                        onClick={() => {
                          setShowPopup(true)
                        }}
                      >
                        {movement.passiveMovement.master.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                    {!movement.passiveMovement && movement.currentGroup &&
                      movement.currentGroup.source === 'LFS' ? (
                      <ProductGroup
                        ampel={stoplight}
                      >
                        {movement.currentGroup.productgroup}
                      </ProductGroup>
                    ) : (
                      ''
                    )}
                  </ProductGroupListItem>
                );
              })}
                 <div style={buttonsBox}>
                <Button
                  onClick={() => {
                    history.push(`/custom-tour/edit-tour`);
                  }}
                  style={{
                    width: '100px',
                    height: '40px',
                    margin: '5px',
                    paddingLeft: '50px',
                    lineHeight: '0',
                    position: 'fixed'
                  }}
                >
                  Edit tour
                </Button>
              </div>
          </div>
        </div>
        </div>
      </>
    )
  );
}

export default CustomTourTemplate;
