import React from 'react';
import { Redirect } from 'react-router-dom';
import UnloadTemplate from '../templates/Unloadtemplate';


function UnloadPage() {

    return (
        <>
            {JSON.parse(localStorage.getItem('beladen')) ? <UnloadTemplate /> : <Redirect to="/tour" />}
        </>
    )
}


export default UnloadPage;