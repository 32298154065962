import React from 'react';

import styled from 'styled-components';

const LabelStyled = styled.div`
    position: relative;
`;

function Label(props) {

    return (
    <LabelStyled {...props}> 
        {props.children}
    </LabelStyled>
    )
}

export default Label;