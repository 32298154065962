import React from 'react';
import Choosedialogtemplate from '../templates/Choosedialogtemplate';


function ChooseDialogPage() {

    return (
        <>
            <Choosedialogtemplate />
        </>
    )
}


export default ChooseDialogPage;