import React from 'react';
import styled from 'styled-components';

const MenuLinkItem = styled.a`
  color: ${({ alert }) =>
    alert ? 'var(--color-warning)' : 'var(--color-grey-light-font)'};
  font-size: 20px;
  font-family: var(--font-accent);
  margin-bottom: 20px;

  &:active {
    color: var(--color-main);
  }
`;

const MenuLink = ({ title, alert, ...props }) => {
  return (
    <MenuLinkItem alert={!!alert} className="col s12" {...props}>
      {title}
    </MenuLinkItem>
  );
};

export default MenuLink;
