import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Headline from '../atoms/headline';
import MenuLink from '../atoms/MenuLink';
import { useMenuToggle } from '../helpers/MenuContext';
import Header from '../organisms/header';
import { store } from '../helpers/StoreContext';
const BottomBar = styled.div`
  position: absolute;
  bottom: 0;
  padding-bottom: 50px;
`;

const MenuWrapper = styled.div`
height: 100%;
position; relative;`;

const User = styled.p`
  font-size: 15px;
  color: var(--color-grey-light-font);

  span {
    color: var(--color-main);
  }
`;

const MenuTemplate = ({ darkMode, toggleDarkMode }) => {
  const globalState = useContext(store);
  const currentTour = globalState && globalState.state && globalState.state.tour;
  const toggleMenu = useMenuToggle();
  const history = useHistory();

  const username = localStorage.getItem('username');

  const handleNavigateToUnloading = () => {
    history.push('/unload');
    toggleMenu();
  };

  const handleNavigateToTourpage = () => {
    history.push('/tour');
    toggleMenu();
  }

  const handleNavigateToTourOverviewpage = () => {
    history.push(`/linefeeding/${currentTour}`);
    toggleMenu();
  }

  const handleToggleDarkMode = () => {
    toggleDarkMode();
  };

  const handleLogout = () => {
    history.push('/logout');
    toggleMenu();
  };

  return (
    <>
      <MenuWrapper className="container">
        <Header inMenu />
        <div className="row">
          <div className="col s12">
            <Headline>Menü</Headline>
          </div>

          {JSON.parse(localStorage.getItem('linefeeder')) && <MenuLink title="Linefeeding" onClick={handleNavigateToTourpage} />}

          {JSON.parse(localStorage.getItem('beladen')) && <MenuLink title="Beladen" onClick={handleNavigateToUnloading} />} 

          {currentTour ? (
            <MenuLink
            title={`Direkt zu ${currentTour}`}
            onClick={handleNavigateToTourOverviewpage}
          />
          ) : ''}

          <MenuLink
            title={darkMode ? 'Nachtmodus aus' : 'Nachtmodus ein'}
            onClick={handleToggleDarkMode}
          />
          <BottomBar>
            <MenuLink
              alert
              title="Ausloggen"
              onClick={handleLogout}
              style={{ marginBottom: 0 }}
            />
            {!!username && (
              <div className="col s12">
                <User>
                  eingeloggt als <span>{username}</span>
                </User>
              </div>
            )}
          </BottomBar>
        </div>
      </MenuWrapper>
    </>
  );
};

export default MenuTemplate;
