import React from 'react';
import styled from 'styled-components';
import { useMenu } from '../helpers/MenuContext';
import Menutemplate from '../templates/Menutemplate';

const PageWrapper = styled.div`
  height: 100%;
  background: var(--color-grey-light);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: ease-in-out 0.3s;
  z-index: 10000;
  transform: translateY(-100%);

  &.visible {
    transform: translateY(0);
  }
`;

const MenuPage = ({ darkMode, toggleDarkMode }) => {
  const showMenu = useMenu();
  return (
    <PageWrapper className={showMenu ? 'visible' : ''}>
      <Menutemplate darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </PageWrapper>
  );
};

export default MenuPage;
