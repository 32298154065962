import styled from 'styled-components';

export const Listitem = styled.li`
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-left: 18px;
  color: var(--color-grey-light-font);
`;
export const Link = styled.a`
  padding: 0;
  color: var(--color-grey-light-font);
  cursor: pointer;
`;

export const Linklist = styled.ul`
  display: inline-flex;
  font-size: 15px;
  flex-grow: 1;
  justify-content: flex-end;
`;
