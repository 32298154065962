import React from 'react';
import { Redirect } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import Tourtemplate from '../templates/Tourtemplate';


function TourPage() {
    const accessToken = localStorage.getItem('accessToken');
    const tours = useFetch(`${process.env.REACT_APP_API}/master/tours`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return tours && (
        <>
            {JSON.parse(localStorage.getItem('linefeeder')) ? <Tourtemplate  tours={tours}/> : <Redirect to="/unload" />}
        </>
    )
}


export default TourPage;