import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// atoms
import Headline from '../atoms/headline';
import Errormessage from '../atoms/errormessage';
import DescriptionWrapper from '../atoms/description';
import Input from '../atoms/input';
import Label from '../atoms/label';
import InfoItem from '../atoms/infoItem';

// organisms
import Header from '../organisms/header';
import InputArea from '../atoms/inputArea';

const InfoArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 20px;
`;

const Button = styled.a`
  background: var(--color-main);
  font-family: 'Catamaran', sans-serif;
  color: var(--color-main-font);
  border: none;
  height: 66px;
  padding: 0 20px;
  line-height: 66px;
  min-width: 180px;
  width: 100%;
  display: block;
  boder-radius: 8px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  &:active,
  &:focus {
    background: var(--color-main);
  }
`;

const VerticalFiller = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  input {
    margin-bottom: 0;
  }
`;

function TourExecuteTemplate({ delivery, tour }) {
  const history = useHistory();
  const [state, setState] = useState({
    message: '',
    deliverynumber: null,
    delivery: delivery
  });

  const [isReadOnly, setIsReadOnly] = useState(true);

  const refHiddenDeliveryNumberInput = useRef();
  const refDeliveryNumberInput = useRef();

  useEffect(() => {
    const { current } = refHiddenDeliveryNumberInput;
    if(current) current.focus();
  }, [refHiddenDeliveryNumberInput]);

  async function startTransport(deliverynumber) {
    if (state.delivery.passiveMovement.delivery !== deliverynumber) {
      setState({
        ...state,
        deliverynumber: '',
        message: 'Falsche Sendungsnummer'
      });
    } else {
      const accessToken = localStorage.getItem('accessToken');
      const transportResponse = await fetch(
        `${process.env.REACT_APP_API}/movement/transport/${deliverynumber}`,
        {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer'
        }
      );
      const transport = await transportResponse.json();
      if (transport) {
        history.push(`/linefeeding/${tour}`);
      }
    }
  }
  var CHECK_DELIVERY = useRef(null);
  function handleChange(e) {
    if (CHECK_DELIVERY.current) {
      clearTimeout(CHECK_DELIVERY.current);
    }

    CHECK_DELIVERY.current = setTimeout(
      startTransport.bind(false, e.target.value),
      100
    );
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const onFocusHandler = (e) => {
    e.currentTarget.blur();
    setIsReadOnly(false);
    refDeliveryNumberInput.current.focus();
  };

  return (
    <>
      <VerticalFiller>
        <div className="container">
          <Header />
          <Headline>Fahre Behälter</Headline>
          <div className="row">
            <DescriptionWrapper>
              {state.delivery.passiveMovement.master.productdescription}
            </DescriptionWrapper>
            <InfoArea>
              <InfoItem
                headline="Takt"
                content={`${state.delivery.passiveMovement.sequenceStart} -
            ${state.delivery.passiveMovement.sequenceEnd}`}
              />
              <InfoItem
                headline="Send.-Nr."
                content={state.delivery.passiveMovement.delivery}
              />
              <InfoItem
                headline="Tor"
                content={state.delivery.passiveMovement.master.unloadingGate}
              />
            </InfoArea>
          </div>
        </div>
        <InputArea>
          <Label>
            <div>
              {state.message ? (
                <Errormessage>{state.message}</Errormessage>
              ) : (
                ''
              )}
              {state.delivery.passiveMovement.unloadedAt || !state.delivery.passiveMovement.master.unloadingNeeded ? (
                <>
                  <Input
                    type="text"
                    icon="scan"
                    value={state.deliverynumber}
                    onChange={handleChange}
                    name="deliverynumber"
                    placeholder="SENDUNG SCANNEN"
                    readOnly={isReadOnly}
                    inputRef={(ref) => (refDeliveryNumberInput.current = ref)}
                  />
                  {/* Disabled Keybord on input autofocus */}
                  <input
                    style={{ opacity: 0 }}
                    placeholder="SENDUNG SCANNEN"
                    onFocus={onFocusHandler}
                    name="deliverynumber"
                    value={state.deliverynumber}
                    ref={refHiddenDeliveryNumberInput}
                  />
                </>
              ) : (
                <>
                  <Errormessage>Sendung noch nicht entladen</Errormessage>
                  <div>
                    <Button
                      onClick={() => {
                        history.push(`/unload`);
                      }}
                    >
                      Zum Entladedialog
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Label>
        </InputArea>
      </VerticalFiller>
    </>
  );
}

export default TourExecuteTemplate;
