import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Back from '../../assets/back.svg';

const NavStyled = styled.nav`
  background-color: initial;
  border: none;
  box-shadow: none;
  height: 40px;
  color: #8f8f98;
  font-size: 22px;
  line-height: 40px;
  display: flex;
  margin-top: 25px;
  margin-bottom: 0;
`;

const Linklist = styled.ul`
  display: inline-flex;
  font-size: 15px;
  flex-grow: 1;
  justify-content: flex-end;
`;
const Listitem = styled.li`
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-left: 18px;
  color: var(--color-grey-light-font);
`;
const Link = styled.a`
  padding: 0;
  color: var(--color-grey-light-font);
`;

function Nav(props) {
  const isLoggedIn = localStorage.getItem('accessToken');
  let history = useHistory();
  return (
    <NavStyled {...props}>
      {props.children}
      {isLoggedIn ? (
        <Linklist>
          {props.inMenu ? (
            <Listitem className="close-icon">Schließen</Listitem>
          ) : (
            <Listitem className="back-icon">
              <Link onClick={ () => {
                history.goBack();
              }} >Zurück</Link>
            </Listitem>
          )}
        </Linklist>
      ) : null}
    </NavStyled>
  );
}

export default Nav;
