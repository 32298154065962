import React from 'react';

import styled from 'styled-components';

import passwordIcon from '../../assets/password-icon.svg';

const PasswordIconStyled = styled.span`
  position: absolute;
  background: url(${passwordIcon});
  background-repeat: no-repeat;
  bcakground-position: center;
  background-size: contain;
  width: 21px;
  height: 21px;
  left: 20px;
  top: 22px;
`;

function PasswordIcon(props) {
  return <PasswordIconStyled {...props} />;
}

export default PasswordIcon;
