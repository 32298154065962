import React from 'react';
import LoginTemplate from '../templates/Logintemplate';


function Loginpage() {

    return (
        <>
            <LoginTemplate />
        </>
    )
}


export default Loginpage;