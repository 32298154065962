import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { store } from '../helpers/StoreContext';

// atoms
import Headline from '../atoms/headline';
import Choice from '../atoms/choice';
import CustomTourChoice from '../atoms/custom-tour-choice';

// organisms
import Header from '../organisms/header';

function TourTemplate({ tours }) {
  const globalState = useContext(store);
  let history = useHistory();
  const { dispatch } = globalState;
  const [state] = useState({
    message: ''
  });

  return (
    <>
      <div className="container">
        <Header />

        <div className="row">
          <div className="col s12">
            <Headline>Tour wählen</Headline>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            {state.message ? state.message : ''}
            {tours &&
              tours.map((tour, index) => {
                return (
                  <Choice
                    key={`tour-index-${index}`}
                    onClick={() => {
                      dispatch({ type: 'CHANGE_TOUR', payload: tour.tour });
                      history.push(`/linefeeding/${tour.tour}`);
                    }}
                  >
                    {tour.tour}
                  </Choice>
                );
              })}
            {localStorage.getItem('userType') === 'ADMIN' ? (
              <CustomTourChoice />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default TourTemplate;
