import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Input from '../atoms/input';
import Header from '../organisms/header';
import Headline from '../atoms/headline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { withStyles } from "@material-ui/core/styles";

const ProductGroupListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .disabled {
      color: #f4f4f4 !important;
    }
  `;

  const ProductGroup = styled.a`
    color: white;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    margin-right: 12px;
    margin-bottom: 10px;
    background: #c95959;
    ${(props) =>
      props.ampel &&
      props.ampel === 'green' &&
      `
        background: #73AC5E
    `}

    ${(props) =>
      props.ampel &&
      props.ampel === 'orange' &&
      `
        background: #EE7203
    `}
    ${(props) =>
      props.ampel &&
      props.ampel === 'grey' &&
      `
          background: grey
      `}
      ${(props) =>
      props.ampel &&
      props.ampel === 'blue' &&
      `
        background: #797eff
      `}
  `;

  const SerachInput = styled(Input)`
  background: var(--color-input-background-onwhite) !important;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  color: var(--color-main);
  margin-bottom: 20px;
`;

  const Submit = styled.button`
  order: 2;
  width: 171px;
  height: 40px;
  font-size: 20px;
  color: var(--color-main-font);
  left: 320px;
  border: none;
  background: #ee7203;
  border-radius: 8px;
  `;
 
  const Cancel = styled.button`
  order: 1;
  width: 171px;
  height: 40px;
  font-size: 20px;
  color: var(--color-main-font);
  right: 320px;
  border: none;
  background: #ee7203;
  border-radius: 8px; 
`;

  const Buttonsbox = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  bottom: 0;
  height: 88px;
  left: 0px;
  background-color: var(--color-main-background);
`;

  const CustomColorCheckbox = withStyles({
    root: {
      color: "#767b84",
      '&$checked': {
        color: '#edeff3',
      },
    },
    checked: {}
  })((props) => <Checkbox color="default" {...props} />);

function EditTourTemplate({ productGroup }) {
  const [productGroups, setproductGroups] = useState([]);
  const [selectedGroupsId, setselectedGroupsId] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  useEffect(() => {
  setproductGroups(productGroup);
  }, []);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setselectedGroupsId(selectedGroupsId => [...selectedGroupsId, e.target.value]);
    } 
    else {
      setselectedGroupsId(selectedGroupsId.filter((v) => v !== value));
    }
  }; 

  const handleSubmit = async () => {
    localStorage.setItem('customGroups', JSON.stringify(selectedGroupsId));
    history.goBack();
  };

  const handleCancel = () => {
    history.goBack();
  }

  const findGroup = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const results = productGroup.filter(group =>
      group.productgroup.includes(searchTerm.toUpperCase())
    );
    setproductGroups(results);
  }, [searchTerm, productGroup]);
  return (
    <>
      <div className="container">
        <Header />
        <div className="row">
          <div className="col s12">
            <Headline>Customise your TOUR</Headline>
            <SerachInput 
              type="text"
              name="search"
              onChange={findGroup}
              placeholder="Search..."
              value={searchTerm}
            />
            <FormControl component="fieldset" style={{"margin-bottom": "80px"}}>
              {productGroups &&
                productGroups.map((group, index) => {
                  return (
                    <ProductGroupListItem key={index}>
                      <FormControlLabel
                        control={
                          <CustomColorCheckbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<RadioButtonCheckedIcon />}
                            checked={selectedGroupsId.includes(
                              (group.id).toString()
                            )}
                            onChange={handleChange}
                            value={group.id}
                          />
                        }
                      ></FormControlLabel>
                      <ProductGroup>{group.productgroup}</ProductGroup>
                    </ProductGroupListItem>
                  );
                })}
            </FormControl>
          </div>
        </div>
      </div>
      <Buttonsbox>
        <Submit onClick={handleSubmit}>
          Confirm
        </Submit>
        <Cancel onClick={handleCancel}>
          Cancel
        </Cancel>
      </Buttonsbox>
    </>
  );
}

export default EditTourTemplate;