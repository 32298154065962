import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: -2rem;
  padding: 0 0.75rem;
`;

const Table = styled.table`
  border-collapse: separate;

  tr {
    border-bottom: none;
  }

  td,
  th {
    padding: 0.6rem 0.8rem;
  }

  td:not(:first-child),
  th:not(:first-child) {
    text-align: right;
  }

  thead {
    tr {
      color: #8f8f99;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: #e6e6e6;
    }

    td:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    td:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      color: #ed8632;
    }
  }
`;

const UnloadingProcessTable = ({ unloadedItems }) => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>DATUM - ZEIT</th>
            <th>PRODUKT</th>
            <th>{unloadedItems.delivery ? 'SENDUNG' : 'TAKT'}</th>
          </tr>
        </thead>

        <tbody>
          {unloadedItems.map((item, index) => (
            <tr key={index}>
              <td>
                {format(new Date(item.unloadedAt), 'dd.MM.yy - HH:mm:ss')}
              </td>
              <td>{item.master.productgroup}</td>
              <td>
                {item.delivery || `${item.sequenceEnd}${item.master.postfix}`}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default UnloadingProcessTable;
