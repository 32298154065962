import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: var(--color-main-background);
  color: var(--color-warning);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 15px;
  line-height: 35px;
  font-family: var(--font-accent);
  margin-bottom: 10px;
`;

const Errormessage = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Errormessage;
