import React, { useState } from 'react';

// atoms
import Headline from '../atoms/headline';
import Button from '../atoms/button';
import Header from '../organisms/header';
import plusIcon from '../../assets/plus.png';
import Label from '../atoms/label';
import Input from '../atoms/input';
import Form from '../atoms/form';
import Labeltext from '../atoms/labeltext';

function BeltSpeedTemplate(props) {
    const [state, setState] = useState({
        beltspeed: props.beltspeed.speed
    })

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function handleKeyup(event) {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    async function handleSubmit() {
        var r = window.confirm("Möchtest du die Bandgeschwindigkeit wirklich ändern?");
        if(r === true) {
            const response = await fetch(`${process.env.REACT_APP_API}/beltspeed`, {
                method: 'PATCH',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    speed: state.beltspeed
                })
            });
            if (response) {
                window.location.reload(false);
            }
        }

    }

    return (
        <div className="container">
            <Header />

            <div className="row">
                <div className="col s6">
                    <Headline>Bandgeschwindigkeit</Headline>
                </div>
                <div className="col s6">
                    <Button icon={plusIcon} size="medium" className="right" onClick={() => {
                        handleSubmit()
                    }}>Speichern</Button>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <Form>
                        <div className="row">
                            <div className="col s12">
                                <Labeltext>Bandgeschwindigkeit. Bspw: 3,25 minuten sind 3 minuten und 15 Sekunden</Labeltext>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m3">
                                <Label>
                                    <Input icon={false} type="number" onChange={handleChange} onKeyUp={handleKeyup} value={state.beltspeed} name="beltspeed" placeholder="3,25" />
                                </Label>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div >
    )
}

export default BeltSpeedTemplate;
