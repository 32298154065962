import React from 'react';
import TourExecuteTemplate from '../templates/Tourexecutetemplate';
import useFetch from '../../hooks/useFetch';

function TourExecutePage(props) {
    const passive = props.match.params.passive;
    const active = props.match.params.active;
    const tour = props.match.params.tour
    const accessToken = localStorage.getItem('accessToken');
    
    const delivery = useFetch(`${process.env.REACT_APP_API}/movement/forscan/${passive}/${active}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    
    return tour && delivery &&  (
        <>
            <TourExecuteTemplate delivery={delivery} tour={tour}/>
        </>
    )
}


export default TourExecutePage;