import React from 'react';

import styled from 'styled-components';

import boxIcon from '../../assets/box-icon.svg';

const BoxIconStyled = styled.span`
  position: absolute;
  background: url(${boxIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 21px;
  height: 100%;
  left: 20px;
`;

function BoxIcon(props) {
  return <BoxIconStyled {...props} />;
}

export default BoxIcon;
